<template>
  <div id="app">
    <!--HEADER-->
    <header @mousemove="mouseMove">
      <NavBar :resolution="resolution"></NavBar>
    </header>

    <div class="back-top hide" @click="goTop()">
      <font-awesome-icon
        icon="fas fa-chevron-up"
        class="cursor"
        alt="go-to-top-icon"
        size="2x"
      />
    </div>

    <!--INTRODUCTION-->
    <section id="about" @mousemove="mouseMove">
      <Intro-scene :mousePosition="this.mousePosition" />
    </section>

    <!--SKILLS-->
    <Skills-compo />

    <!--PROJECTS-->
    <Project-compo />

    <!--CONTACT-->
    <contact-component />

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import IntroScene from "./components/IntroScene.vue";
import SkillsCompo from "@/components/SkillsCompo.vue";
import ProjectCompo from "@/components/ProjectsComponent.vue";

export default {
  components: {
    ContactComponent,
    NavBar,
    IntroScene,
    SkillsCompo,
    ProjectCompo,
  },

  data: () => ({
    mousePosition: { x: 0, y: 0 },
    navigationRight: {},
    resolution: {
      width:0,
      height:0
    }
  }),

  mounted() {
    this.navigationRight = document.querySelector(".back-top");
    document.addEventListener("scroll", this.handleScrolling);
    
    this.getResolution()
    addEventListener('resize',this.getResolution)
  },
  methods: {
    getResolution(){
      this.resolution.width = window.innerWidth
      this.resolution.height = window.innerHeight
    },
    /**
     * Go to the top of the page.
     */
    goTop() {
      window.scrollTo(0, 0);
    },

    /**
     * Get the cursor position.
     */
    mouseMove(mouseEvent) {
      this.mousePosition = { x: mouseEvent.clientX, y: mouseEvent.clientY };
    },

    /**
     * Manage the scroll for the item scroll to top.
     */
    handleScrolling() {
      this.handleScrollBack(200);
    },

    /**
     * If the user is scrolling below the height show the scroll back item else hide it.
     * @param: limit scrolling limit to show the item */
    handleScrollBack(limit) {
      if (window.scrollY > limit) {
        this.navigationRight.classList.remove("hide");
      } else {
        this.navigationRight.classList.add("hide");
      }
    },

  },
};
</script>


<style src="@/assets/style/main.css">