<template>
        <button> <slot></slot></button>
</template>


<script>
export default{
name:"ButtonProject",
}
</script>

<style scoped>
button {
    background-color: var(--section-color-odd) ;
    color: var( --txt-hl-light) ;
    text-decoration: none;
    padding: 10px 22px;
    font-weight: bolder;
    transition:all 1s;
    border:  var( --txt-hl-light)  1px solid 
}

 button:hover{
    background-color: var( --txt-hl-light) ;
    color: var(--section-color-odd);
    border:  var(--section-color-odd) 1px solid;
    transition:all 1s;
    cursor: pointer;
}
</style>