<template>
  <section id="skills">
    <div id="container-skills">
      <div>
        <!--COMPETENCES-->
        <H2>[ Compétences ]</H2>
        <div class="skill" v-for="(skill, id) in skills" :key="id">
          <h3>{{ skill.title }}</h3>
          <div class="bar-value">
            <div class="value" style="width: 10%"></div>
          </div>
        </div>

        <!--TECHNOLOGIE-->
        <div>
          <H2>[ Technologies ]</H2>
          <div id="container-techno">
            <!--FIRST ROW STACK-->
            <div class="experience-row">
              <div
                class="skills"
                v-for="(techno, id) in technologies.firstRow"
                :key="id"
              >
                <img  width="200" height="200" :src="getImg(techno.img)" :alt="techno.alt" />
              </div>
            </div>

            <!--SECOND ROW STACK-->
            <div class="experience-row">
              <div
                class="skills-back"
                v-for="(techno, id) in technologies.secondRow"
                :key="id"
              >
                <img  width="200" height="200" :src="getImg(techno.img)" :alt="techno.alt" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--EXPERIENCE-->
      <div>
        <H2>[ Parcours ]</H2>
        <div id="right-container">
          <div id="exp-container">
            <ul class="exp">
              <li v-for="(exp, id) in experiences" :key="id" class="exp-item">
                <div class="timestamp">{{ exp.date }}</div>
                <div>
                  <p v-for="(txt, idd) in exp.content" :key="idd">{{ txt }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Bonus -->
    <div id="bonus-container">
      <div class="bonus" v-for="(categorie, id) in categories" :key="id">
        <h3>{{ categorie.label }}</h3>
        <div>
          <font-awesome-icon
            :icon="categorie.icon"
            size="3x"
            :alt="categorie.alt"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  experiences,
  skills,
  technologies,
  categories,
} from "/public/data/FR-fr/experience.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "SkillsCompo",
  components: {},

  data: () => ({
    experiences,
    skills,
    technologies,
    categories,
    path: "@/assets/img/stack/",
  }),

  methods: {
    /* Show a place holder if the image is not found. */
    getImg(imgName) {
      try {
        return require(`../assets/img/stack/` + imgName);
      } catch {
        return require(`../assets/img/place-holder.jpg`);
      }
    },
    /**
     * Tiles's animation.
     */
    animationTiles(tiles) {
      for (let i = 0; i < tiles.length; i++) {
        gsap.to(tiles[i], {
          scrollTrigger: {
            trigger: tiles[i],
            start: "0 90%",
            toggleActions: "play",
          },
          opacity: 1,
          ease: "power1.inOut",
          duration: 0.7,
          delay: 0.2 * i,
        });
      }
    },
    /**
     * Tiles's Back animation.
     */
    animationTilesBack(tilesBack) {
      for (let i = tilesBack.length - 1; i >= 0; i--) {
        gsap.to(tilesBack[i], {
          scrollTrigger: {
            trigger: tilesBack[i],
            start: "0 95%",
            toggleActions: "play",
          },
          opacity: 1,
          ease: "power1.inOut",
          duration: 0.7,
          delay: 1 - 0.2 * i,
        });
      }
    },
    animationProgressBar(value) {
      for (let i = 0; i < value.length; i++) {
        //Width bar animation
        gsap.to(value[i], {
          scrollTrigger: {
            trigger: value[i],
            start: "0 99%",
            toggleActions: "play",
          },
          width: this.skills[i].value + "%",
          duration: 0.1,
          delay: 0.1 * i,
        });

        //Text percent animation
        gsap.to(value[i], {
          scrollTrigger: {
            trigger: value[i],
            start: "0 99%",
            toggleActions: "play",
          },

          innerText: this.skills[i].value + "%",
          duration: 2.5,
          snap: {
            innerText: 5,
          },
        });
      }
    },
  },

  mounted() {
    /* Stack display animations */
    gsap.to(".skills", {
      scrollTrigger: {
        trigger: ".skills",
        start: "0% 90%",
        toggleActions: "play",
      },
      opacity: 1,
      ease: "power1.inOut",
      duration: 0.5,
      stagger: 0.2,
    });

    gsap.to("#exp-container ul.exp li", {
      scrollTrigger: {
        trigger: "#exp-container ul.exp li",
        start: "0% 80%",
        toggleActions: "play",
      },
      duration: 1,
      opacity: 1,
      stagger: 0.5,
    });

    let skillsBack = document.querySelectorAll(".skills-back");
    this.animationTilesBack(skillsBack);

    /* Progress bar animations */
    const progressBar = document.querySelectorAll(".value");
    this.animationProgressBar(progressBar);
  },
};
</script>

<style scoped>
@import url("@/assets/style/skills.css");
</style>