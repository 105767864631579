<template>
  <div :class="['info-container', type]">
    <div :class="['left-col',getBackgroundColor] ">
      <font-awesome-icon :icon="['fas', getClassIcon] " size="2xl" />
    </div>
    <div class="information">
      <ul :class="showListDecoration">
        <li v-for="data in datas" :key="data">{{ data }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "InformationDisplay",

  props: {
    type: {
      type: String,
      required: true,
    },
    datas: {
      type: Array,
      required: true,
    },
  },

  computed: {
      getBackgroundColor(){
 if (this.type === "success") {
        return "success-container";
      }

      return "error-container"
      },
    getClassIcon(){
 if (this.type === "success") {
        return "fa-check-circle";
      }

      return "fa-exclamation-circle";
    },
    showListDecoration(){
        return this.datas.length>1?'squared':''
    }
  },
};
</script>

<style scoped>
.left-col {
    display: flex;
    align-items: center;
    padding: .6em;
    color: rgba(255, 255, 255, 0.6);
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}

.info-container {
    margin-top: 30px;
    display: flex;
    justify-content: start;
    border-radius: 8px;
}

.error-container {
        background-color: var(--error-color);
}

.error{
    border: 1px var(--error-color) solid;
}
.success-container{
        background-color: var(--success-color);

}
.success{
    border: 1px var(--success-color) solid;
}

.info-container ul {
    padding: .6em;
    margin-left: 1em;
  
}
.information{
    margin: auto;
}
.squared {
  list-style-type: square;
  margin-left:.6em !important;
}
</style>