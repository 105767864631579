<template>
  <header>
  

 
    <!-- NEW NAV -->
    <nav>
      <div id="nav-left">
        <div
          id="burger-icon"
          class="menu-icon"
          @click="showSlideMenu = !showSlideMenu"
        >
          <font-awesome-icon :icon="displayBurgerIcon" size="2x" />
        </div>
        <!-- MENU -->
        <div id="menu" v-if="displayMenu">
          <ul>
            <li @click="goAnchor('#skills')">  <font-awesome-icon icon="fas fa-cog"/> <span class="separator"> |</span> COMPETENCES</li>
            <hr  class="separator" />
           <li @click="goAnchor('#projects')">  <font-awesome-icon icon="fas fa-folder " /><span class="separator"> |</span> MES REALISATIONS</li>
             <hr class="separator"  />
            <li @click="goAnchor('#contact')"> <font-awesome-icon icon="fas fa-envelope" /><span class="separator"> |</span> ME CONTACTER</li>
          </ul>
        </div>
      </div>
      <!-- SWITCH THEME ICON -->
      <div class="fix-icon">
        <div id="light-icon" class="menu-icon" @click="switchTheme()">
         
         <font-awesome-icon
            :icon="displayThemeIcon"
            size="2x"
            style="padding: 0 5px"
          />
        </div>
        
      </div>

    </nav>
  </header>
</template>


<script>
export default {
  name: "NavBar",
  props: {
    resolution: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showSlideMenu: false,
  }),
  computed: {
    displayThemeIcon() {
      return this.$store.state.darkmode ? "fas fa-sun" : "fas fa-moon";
    },
    displayBurgerIcon() {
      return this.showSlideMenu ? "far fa-window-close" : "fas fa-bars";
    },
    displayMenu() {
      if (this.resolution.width >= 768) {
        return true;
      }

      return this.showSlideMenu;
    },
    displayThemeTips(){
      return this.$store.state.darkmode ? "Thème clair" : "Thème sombre"
    }
  },
  mounted() {
    // addEventListener('resize',
    //   this.displayMenu
    // )
  },
  methods: {
    /**
     * Scroll to the selector page & close the menu slide min.
     *
     * @param {
     * } selector  selector to scroll.
     */
    goAnchor(selector) {
      var anchor = document.querySelector(selector);
      this.showSlideMenu = this.showSlideMenu ? false : false;
      window.scrollTo(0, anchor.offsetTop);
    },
    /**
     * Switch the theme of the website.
     */
    switchTheme() {
      this.$store.commit("switchTheme");
      document.documentElement.classList.toggle("dark-mode"); //Switch css
    },
  },
};
</script>

 
<style src="@/assets/style/menu.css" scoped />