export const skills =
    [
        {
            "title": "HTML, CSS, BOOTSTRAP",
            "value": "60"
        },
        {
            "title": "PHP, MYSQL, SYMFONY",
            "value": "60"
        },
        {
            "title": "JS ES6, VUE JS",
            "value": "50"
        },
        {
            "title": "C#",
            "value": "30"
        },
    ]

export const experiences =
    [
        {
            "date": "OCTOBRE 2022",
            content: ["Obtention du titre professionnel (niveau 5) Développeur web et web mobile."]
        },
        {
            "date": "JUILLET-AOUT 2022",
            content: ["Stage Deliwrapp à lyon :",
                "Modélisation de base de données, suivi de production, développement back-end sur Symfony et documentation technique.",
            ]
        },
        {
            "date": "OCTOBRE 2021- JUILLET 2022",
            content: ["Formation développeur web et web mobile à la CCI lyon.",
                "Modules principaux : Php, Javascript, Wordpress, VueJS et Symfony."]
        }
    ]

export const technologies =
{
    firstRow: [
        {
            "img": "html-logo.png",
            "alt": "html-logo"
        },
        {
            "img": "git-logo.png",
            "alt": "git-logo"
        },
        {
            "img": "javascript-logo.png",
            "alt": "javascript-logo"
        },
        {
            "img": "wordpress-logo.png",
            "alt": "wordpress-logo"
        },
        {
            "img": "vue-logo.png",
            "alt": "vueJs-logo"
        },
    ],
    secondRow: [
        {
            "img": "css-logo.png",
            "alt": "css-logo"
        },
        {
            "img": "php-logo.png",
            "alt": "php-logo"
        },
        {
            "img": "mysql-logo.png",
            "alt": "mysql-logo"
        },
        {
            "img": "symfony-logo.png",
            "alt": "symfony-logo"
        },
        {
            "img": "docker-logo.png",
            "alt": "docker-logo"
        },
    ]
}

export const categories = [
    {
        "label": "Veille technologique",
        "icon": "fa fa-flask-vial",
        "alt": "icon-flask-vial"
    },
    {
        "label": "Travail en équipe",
        "icon": "fa fa-people-group",
        "alt": "icon-people"
    },
    {
        "label": "Beaucoup de Passion",
        "icon": "fa fa-heart",
        "alt": "icon-heart"
    },
]