<template>
  <!--LOADER-->
  <div v-if="isSendingMail" id="send-mail">
    <div class="spinner"></div>
    <p>Envoi du message en cours ...</p>
  </div>

  <div v-else>
    <!--SUCCESS MESSAGE-->
        <information-display v-if="hideForm" :type="'success'" :datas="['Votre message a bien été envoyé !']" />
 
    <!--FORM-->
    <form @submit.prevent="sendEmail" v-else>
      <h3>Me laisser un message</h3>
      <div><input type="hidden" name="contact_number" /></div>

      <!-- NAME -->
      <div id="align-input">
        <input
          type="text"
          name="user_name"
          placeholder="Nom/Prénom"
          v-model="form.name"
          maxlength="30"
          :class="{ 'error-form': $v.form.name.$error }"
        />

        <!-- EMAIL -->
        <input
          name="user_email"
          placeholder="Email"
          v-model="form.email"
          maxlength="100"
          :class="{ 'error-form': $v.form.email.$error }"
        />
      </div>

      <!-- MESSAGE -->
      <div>
        <textarea
          name="message"
          id=""
          cols="30"
          rows="5"
          maxlength="250"
          placeholder="Votre message"
          v-model="form.message"
          :class="{ 'error-form': $v.form.message.$error }"
        ></textarea>
      </div>

      <!-- ERROR FORM -->
      <information-display v-if="$v.form.$error" :datas="showError" :type="'error'" />

      <!-- ERROR API -->
      <information-display v-if="apiError.length>0" :datas="apiError" :type="'error'" />

      <div class="send">
        <button-project>ENVOYER</button-project>
      </div>
    </form>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import ButtonProject from "./ui/ButtonComponent.vue";
import InformationDisplay from './ui/InformationComponent.vue';
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "ContactForm",
  components: {
    ButtonProject,
    InformationDisplay
  },

  data: () => ({
    form: {
      name: "",
      email: "",
      message: "",
    },
    apiError: [],
    hideForm: false,
    isSendingMail: false,
  }),

  validations: {
    form: {
      name: {
        required,
        minLength: minLength(4),
      },
      email: {
        required,
        email,
      },
      message: {
        required,
        minLength: minLength(10),
      },
    },
  },

  computed: {
    /* return errors with the contact form */
    showError() {
      let errors = [];
      // Name
      if (!this.$v.form.name.required) {
        errors.push("Votre nom ou prénom est requis.");
      } else if (!this.$v.form.name.minLength) {
        errors.push("Votre nom/prénom est trop court.");
      }

      // Message
      if (!this.$v.form.message.required) {
        errors.push("Un message est requis.");
      } else if (!this.$v.form.message.minLength) {
        errors.push("Votre message n'est pas assez long.");
      }

      // Email
      if (!this.$v.form.email.required) {
        errors.push("Votre email est requis.");
      } else if (this.$v.form.email.$invalid) {
        errors.push("Cet email n'est pas valide.");
      }

      return errors;
    },
  },

  methods: {
    /** Send Email via API */
    sendEmail(e) {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isSendingMail = true;

        try {
          emailjs
            .sendForm(
              "service_w94zr4t",
              "template_ah0hnzh",
              e.target,
              "lTg6uB3SSqdJZhtqN",
              {
                name: this.name,
                email: this.email,
                message: this.message,
              }
            )
            .then(
              () => {
                this.isSendingMail = false;
                this.hideForm = true;
              },

              (error) => {
                this.isSendingMail = false;
                this.apiError.push(error);
              }
            );
        } catch (error) {
          this.isSendingMail = false;
          this.apiError.push("Une erreur inconnue est survenue, veuillez ressayer plus tard.");
        }
      }
    },
  },
};
</script>

<style scoped>
@import "@/assets/style/form.css";
</style>