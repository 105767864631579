<template>
  <div>
    <div class="container-3d" id="about">
      <div class="loader">
        <div class="loading"></div>
        <div class="value-loading"></div>
        <div>CHARGEMENT DE LA SCENE</div>
      </div>
    </div>

    <div class="container-about">
      <div class="intro">
        <div class="left">
          <div>
            <h1>Bienvenue sur mon portfolio !</h1>
          </div>
          <div class="sub-title"></div>
        </div>
        <div class="right">
          <div class="photo">
            <img
              width="124"
              height="124"
              :src="require(`../assets/img/photo.png`)"
              alt="avatar-image"
            />
          </div>
        </div>
      </div>
      <div></div>
      <div class="intro-txt">
        <p>
          Je suis Steve, développeur web frontend et backend, passionné par tout
          ce qui gravite autour du web. Vous pouvez découvrir sur ce site web mes
          <a href="#projects">derniers projets</a> et me
          <a href="#contact">contacter</a>.
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>


<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export default {
  name: "IntroScene",
  props: {
    mousePosition: {
      type: Object,
    },
  },

  data: () => ({
    isLoading: true,

    /* 3D Scene main */
    isOnScreen: true,
    scene: undefined,
    render: undefined,
    camera: undefined,
    controls: undefined,
    textures: undefined,
    neck: undefined,

    clock: new THREE.Clock(),
    mixer: undefined,

    iFrameDark: 1,
    timerSwitch: 0,
    switchFrameDark: 0,

    statusIdle: true,
    timerIdle: 0,
    oldMouse: undefined,

    oldSwitch: false,

  }),
  methods: {
    /**
     * Observer : checks if the scene is in viewport
     */
    handler(entries) {
      for (let entry of entries) {
        if (entry.isIntersecting) {
          this.isOnScreen = true;
        } else {
          this.isOnScreen = false;
        }
      }
    },

    /**
     * Boucle principale de la scène.
     */
    animate() {
      if (this.mousePosition != undefined) {
        if (!this.statusIdle) {
          this.moveHead(this.mousePosition, this.neck, 43, 28);
        } else {
          //retour du bone neck a l'emplacement d'origine
          this.returnHeadToOrigin();
        }
        //gestion de l'animation idle
        if (this.mixer) {
          this.idleStateHandler();
        }
      }
      //Changement de thème.
      if (this.oldSwitch != this.$store.state.darkmode) {
        this.switchTheme();
      }

      this.cameraRotateBounce();
      this.controls.update();

      if (this.isOnScreen) {
        this.render.render(this.scene, this.camera);
      }
      //Animation du mode sombre.
      if (this.$store.state.darkmode && this.statusIdle) {
        this.animateDarkMode();
      }

      //Animation plante + personnage idle.
      if (this.mixer) {
        this.mixer.update(this.clock.getDelta());
      }

      requestAnimationFrame(this.animate);
      this.oldSwitch = this.$store.state.darkmode;
      this.oldMouse = this.mousePosition;
    },

    /**
     * Switch theme.
     */
    switchTheme() {
      if (this.$store.state.darkmode) {
        //Thème nuit.
        this.applyTexture(1);
      } else {
        //Thème jour.
        this.applyTexture(0);
        this.scene.children[0].intensity = 1;
      }
    },

    /**
     * Obtenir un nombre aléatoire.
     */
    getRandom(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    /**
     * Modifie la texture du modèle.
     * iTexture l'index de la texture à appliquer.
     * todo : erreur ici ! parfois il ne trouve pas le children :|
     */
    applyTexture(iTexture) {
      if (this.scene.children) {
        for (let i = 0; i < this.scene.children[1].children.length; i++) {
          if (this.scene.children[1].children[i].name === "bureau") {
            this.scene.children[1].children[i].material.map =
              this.textures[iTexture];
          }
        }
      }
    },

    /**
     * Mise à jour de la taille de la scène 3D.
     */
    changeDimensions() {
      let offsetWidth = 1;
      if (window.innerWidth > 768) {
        offsetWidth = 1.2;
      }

      const navHeight = document.querySelector("nav");
      this.render.setSize(
        window.innerWidth / offsetWidth,
        window.innerHeight - navHeight.clientHeight
      );
      this.cameraSet();
    },

    /**
     * Camera settings
     */
    cameraSet() {
      const camFactor = this.getCamFactor();
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.left = -window.innerWidth / camFactor;
      this.camera.right = window.innerWidth / camFactor;
      this.camera.top = window.innerHeight / camFactor;
      this.camera.bottom = -window.innerHeight / camFactor;
      this.camera.updateProjectionMatrix();
    },

    /**
     * Calculer le factor de camera selon la taille de la page.
     */
    getCamFactor() {
      if (window.innerWidth < 738) return 3.5;

      if (window.innerWidth < 1300) return 4.8;

      return 6;
    },

    /**
     * Rebond de la caméra de la scène 3D
     */
    cameraRotateBounce() {
      if (this.controls.getAzimuthalAngle() < -0.3) {
        if (this.controls.autoRotateSpeed > 0.0) {
          this.controls.autoRotateSpeed *= -1;
        }
      }
      if (this.controls.getAzimuthalAngle() > 0.55) {
        if (this.controls.autoRotateSpeed < 0.0) {
          this.controls.autoRotateSpeed *= -1;
        }
      }
    },

    /**
     * Animation du mode sombre, deux états possibles.
     */
    animateDarkMode() {
      const SPEED_IFRAME = 1;
      this.timerSwitch += SPEED_IFRAME;

      // Changement de texture de  la scène.
      if (this.timerSwitch > this.switchFrameDark) {
        /**
         * Changement de texture avec la lumière adaptée.
         */
        if (this.iFrameDark == 1) {
          this.iFrameDark = 2;
          this.scene.children[0].intensity = Math.random(0.6, 0.8);
        } else {
          this.iFrameDark = 1;
          this.scene.children[0].intensity = 1;
        }

        //reset du timer.
        this.timerSwitch = 0;
        this.switchFrameDark = this.getRandom(80, 200);

        //Changement de texture.
        this.applyTexture(this.iFrameDark);
      }
    },

    /**
     * Rotation de la tête du personnage
     */
    moveHead(mouse, bone, degreeLimitX, degreeLimitY) {
      const speedMoveHead = 0.06;
      const degreeLimits = { x: degreeLimitX, y: degreeLimitY };

      // Calcul de l'angle sur l'axe X et Y.
      let degrees = this.getMouseDegrees(mouse, degreeLimits);

      //On vérifie que le pivot est bien présent et on effectue la rotation.
      if (bone) this.boneRotate(this.neck, speedMoveHead, degrees.x, degrees.y);
    },

    /**
     * Obtenir l'angle entre le personnage et le curseur de l'utilisateur.
     */
    getMouseDegrees(mouse, degreeLimits) {
      let dx = 0,
        dy = 0,
        xdiff,
        xPercentage,
        ydiff,
        yPercentage;

      let rectanglePage = { x: window.innerWidth, y: window.innerHeight };

      //--Tête regarde à gauche (rotation entre 0 et - degreeLimits.x)

      // Si le curseur est a gauche de la moitié de l'écran
      if (mouse.x <= rectanglePage.x / 2) {
        // La différence entre le millieu de l'écran et la position du curseur
        xdiff = rectanglePage.x / 2 - mouse.x;
        // Calcul du % de cette différence (par rapport au bord de l'écran)
        xPercentage = (xdiff / (rectanglePage.x / 2)) * 100;
        //On converti le % par rapport à la rotation max de la tête du personnage
        dx = ((degreeLimits.x * xPercentage) / 100) * -1;
      }
      //-- Tête regarde à droite (rotation entre 0 et degreeLimits.x)
      if (mouse.x >= rectanglePage.x / 2) {
        xdiff = mouse.x - rectanglePage.x / 2;
        xPercentage = (xdiff / (rectanglePage.x / 2)) * 100;
        dx = (degreeLimits.x * xPercentage) / 100;
      }

      // Tête levée (entre 0 et -degreeLimits.y)
      if (mouse.y <= rectanglePage.y / 2) {
        ydiff = rectanglePage.y / 2 - mouse.y;
        yPercentage = (ydiff / (rectanglePage.y / 2)) * 100;
        // Je multiplie les degrés limites pour faire monter la tête plus haut
        dy = ((degreeLimits.y * 1.2 * yPercentage) / 100) * -1;
      }

      // Tête baissée (entre 0 et degreeLimits.y)
      if (mouse.y >= rectanglePage.y / 2) {
        ydiff = mouse.y - rectanglePage.y / 2;
        yPercentage = (ydiff / (rectanglePage.y / 2)) * 100;
        dy = (degreeLimits.y * 0 * yPercentage) / 100;
      }
      return { x: dx, y: dy };
    },

    /**
     * Rotation de l'os selon la vitesse.
     */
    boneRotate(bone, speed, valueX, valueY) {
      if (bone) {
        // Rotation gauche & droite, deux vitesses todo ou abandonner ?
        if (bone.rotation.y > THREE.MathUtils.degToRad(valueX)) {
          if (bone.rotation.y - THREE.MathUtils.degToRad(valueX) < speed) {
            bone.rotation.y - THREE.MathUtils.degToRad(valueX);
          } else {
            bone.rotation.y -= speed;
          }
        }
        if (bone.rotation.y < THREE.MathUtils.degToRad(valueX)) {
          if (bone.rotation.y - THREE.MathUtils.degToRad(valueX) > speed) {
            bone.rotation.y - THREE.MathUtils.degToRad(valueX);
          } else {
            bone.rotation.y += speed;
          }
        }

        // Rotation haut & bas, une vitesse.
        if (bone.rotation.x > THREE.MathUtils.degToRad(valueY)) {
          bone.rotation.x -= speed;
        }
        if (bone.rotation.x < THREE.MathUtils.degToRad(valueY)) {
          bone.rotation.x += speed;
        }
      }
    },

    /**
     * Retour en état idle de la tête.
     */
    returnHeadToOrigin() {
      const speedMoveHead = 0.04;
      this.boneRotate(this.neck, speedMoveHead, 0, 0);
    },

    /**
     * Gestion du status idle du personnage, actions[1]=animation du personnage idle dans le mixer.
     */
    idleStateHandler() {
      const limitSwitchIdle = 100;
      if (this.oldMouse != undefined) {
        if (this.oldMouse == this.mousePosition) {
          if (!this.statusIdle) {
            this.timerIdle += 1;
            if (this.timerIdle > limitSwitchIdle) {
              this.statusIdle = true;
              this.mixer._actions[1].paused = false;
            }
          }
        } else {
          this.mixer._actions[1].paused = true;

          this.statusIdle = false;
          this.timerIdle = 0;
        }
      }
    },
  },

  mounted() {
    //Check if the 3D scene is on viewport
    let observer = new IntersectionObserver(this.handler);
    observer.observe(document.querySelector(".container-3d"));

    window.addEventListener("resize", this.changeDimensions, false);

    /**
     * Configuration de la scène
     */
    const canva = document.querySelector(".container-3d");
    const PATH_TO_MODEL = "model/scene-3D.glb";

    //render
    let USE_ANTIALIASING = true;
    const IS_ALPHA = true;
    let CONTROL_IS_AUTO_ROTATE = true;

    let modificatorRatio = 1;

    if (window.innerWidth < 768) {
      USE_ANTIALIASING = false; //if used on mobile crash the scene.
      // modificatorRatio = 1; On baisse la qualité en mode portable pour l'optimisation
    }

    //Orbit controls
    const CONTROL_ZOOM = false;
    const CONTROL_PAN = false;
    const CONTROL_DAMPING = true;

    const CONTROL_SPEED_AUTO_ROTATE = 0.4;
    const CONTROL_SPEED_USER_ROTATE = 0.1;

    //Camera
    const CAMERA_ZOOM = 45;
    const CAMERA_POSITION = [0.2503, 2.9707, 4.5465];
    // const IS_AUTO_ROTATE = true;

    //Lumière
    const AMBIENT_LIGHT = 1;
    this.switchFrameDark = this.getRandom(80, 200);

    /**
     * Initialisation de la scène
     */
    this.scene = new THREE.Scene(); //voir si je peux pas mettre en bas plz

    /**
     * Initialisation du moteur de rendu
     */
    this.render = new THREE.WebGLRenderer({
      alpha: IS_ALPHA,
      antialias: USE_ANTIALIASING,
    });

    this.render.outputEncoding = THREE.sRGBEncoding; //Better color

    this.render.setPixelRatio(window.devicePixelRatio * modificatorRatio); //iudem voir a mettre au resize ???

    /**
     * Initialisation de la caméra
     */
    this.camera = new THREE.OrthographicCamera();
    this.camera.position.set(
      CAMERA_POSITION[0],
      CAMERA_POSITION[1],
      CAMERA_POSITION[2]
    );
    this.camera.zoom = CAMERA_ZOOM;

    /*
     * Bar de chargement
     */
    const valueLoading = document.querySelector(".value-loading");

    const loadingManager = new THREE.LoadingManager();
    loadingManager.onProgress = function (url, item, total) {
      valueLoading.innerHTML = Math.round((item / total) * 100) + "%";
    };

    loadingManager.onLoad = () => {
      document.querySelector(".loader").style.display = "none";
      //modèle chargé j'applique la texture de jour
      this.applyTexture(0);

      this.animate();
    };

    /**
     * Chargement des textures.
     * Todo repasser sur la texture de base de jour sans la charger et utiliser celle dans le file modele.
     */
    const textureLoader = new THREE.TextureLoader(loadingManager);
    this.textures = [
      textureLoader.load(`/scene/light_texture.png`),
      textureLoader.load(`/scene/dark_1.png`),
      textureLoader.load(`/scene/dark_6.png`),
    ];

    this.textures.forEach((texture) => {
      // Obtenir un meilleur rendu.
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
    });

    /**
     * Chargement du modèle 3D
     */

    const loader = new GLTFLoader(loadingManager);

    loader.load(PATH_TO_MODEL, (gltf) => {
      const model = gltf.scene;
      if (window.innerWidth < 768) {
        model.position.set(0.5, -1, -2);
      } else {
        model.position.set(0.5, -1.1, 0);
      }
      model.scale.set(1, 1, 1);

      model.traverse(function (object) {
        object.frustumCulled = false;
      }); //On annule le culling (disparition d'objet au niveau de la caméra).

      //Animations
      this.mixer = new THREE.AnimationMixer(model);
      let fileAnimations = gltf.animations;

      //Plante animation
      let clipPlante = THREE.AnimationClip.findByName(
        fileAnimations,
        "plante-move"
      );
      clipPlante = this.mixer.clipAction(clipPlante);
      clipPlante.play();

      //Personnage idle animation
      let clipIdle = THREE.AnimationClip.findByName(
        fileAnimations,
        "perso-idle"
      );
      clipIdle = this.mixer.clipAction(clipIdle);
      clipIdle.play();

      // Récupérer l'os du neck.
      model.traverse((o) => {
        o.frustumCulled = false;
        if (o.isBone && o.name === "neck") {
          this.neck = o;
        }
      });
      this.scene.add(model);
    });

    /**
     * Initialisation des lumières.
     */
    const ambientLight = new THREE.AmbientLight(0xffffff, AMBIENT_LIGHT);

    /**
     * Initialisation des contrôles, désactivé si mobile.
     */
    this.controls = new OrbitControls(this.camera, this.render.domElement);
    this.controls.enableDamping = CONTROL_DAMPING;
    this.controls.enablePan = CONTROL_PAN;
    this.controls.autoRotate = CONTROL_IS_AUTO_ROTATE;
    this.controls.enableZoom = CONTROL_ZOOM;
    this.controls.autoRotateSpeed = CONTROL_SPEED_AUTO_ROTATE;
    this.controls.rotateSpeed = CONTROL_SPEED_USER_ROTATE;
    this.controls.autoRotate = CONTROL_IS_AUTO_ROTATE;

    /**
     * Restriction de la caméra
     */
    /** Limitation Haut et bas de la caméra */
    this.controls.maxPolarAngle = 1.06;
    this.controls.minPolarAngle = 0.9287;
    /** Limitation gauche et droite de la caméra */
    this.controls.maxAzimuthAngle = 0.565;
    this.controls.minAzimuthAngle = -0.305;

    /**
     * Ajouter les éléments à la scène et l'attacher au DOM
     */
    this.scene.add(ambientLight);
    canva.append(this.render.domElement);

    /**
     * Boucle principale
     */

    this.changeDimensions(); //Change dimension in real time
    this.isLoading = false;
  },
};
</script>

<style scoped>
@import "@/assets/style/scene.css";
</style>