import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    darkmode:false
  },
 
  mutations: {
    switchTheme()
    {
      this.state.darkmode=!this.state.darkmode
    }
  },
})