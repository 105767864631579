<template>
  <footer>

    <div id="contact">
      <h2>CONTACT</h2>
    </div>

    <div id="footer-container">

      <!--FORM-->
      <div id="contact-form-container">
          <ContactForm></ContactForm>
      </div>
      <div id="col-separator"></div>
      
      <!--ADRESS+SOCIAL-->
      <div id="contact-adress-container"> 
        <div id="adress">
            <ul>
              <li>
                <h3>Steve Kaci</h3>
              </li>
              <li>St Jean de thurigneux</li>
              <li>Auvergne-Rhône-Alpes</li>
            </ul>
         
        </div>
        <hr>
        <div>
          <div id="social">
            <div>
              <a
                href="https://github.com/steveKac01?tab=repositories"
                target="_blank"
                ><div>
                  <font-awesome-icon
                    icon="fab fa-github "
                    size="3x"
                    alt="github-icon"
                  />
                </div>
                <div class="link-social">Github</div>
              </a>
            </div>
            
            <div>
              <a
                href="https://www.linkedin.com/in/steve-kaci-4741a5260/"
                target="_blank"
                ><div>
                  <font-awesome-icon
                    icon="fab fa-linkedin  "
                    size="3x"
                    alt="linkedin-icon"
                  />
                </div>
                <div class="link-social">Linkedin</div>
              </a>
            </div>

            <div>
              <a :href="'mailto:' + $options.email"
                ><div>
                  <font-awesome-icon
                    icon="fas fa-at "
                    size="3x"
                    alt="envelope-icon"
                  />
                </div>
                <div class="link-social">Email</div>
              </a>
            </div>
          </div>

          <hr>

          <!--NAVIGATION-->
          <div id="navigation">
            <h3>Navigation</h3>
            <ul>
              <li><a href="#about">A propos de moi</a></li>
              <li>
                <span class="nav-separator"> | </span>
                <a href="#skills">Compétences</a>
              </li>
              <li>
                <span class="nav-separator"> | </span>
                <a href="#projects">Mes projets </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--COPYRIGHT-->
    <div id="copyright">2022-2023 | Portfolio de Steve Kaci</div>
  </footer>
</template>

<script>
import ContactForm from "@/components/FormComponent.vue";

export default {
  name: "ContactComponent",
  email: "kaci.steve@free.fr",
  components: {
    ContactForm,
  },
};
</script>

<style scoped>
@import url("@/assets/style/contact.css");
</style>