export const projects =
    [
        {
            "title": "Endless space",
            "alt": "projet-jeu-video",
            "content": "Projet présenté pour la soutenance de mon titre développeur web. Constitué d'une API REST réalisée avec Symfony, d'un webservice en vue JS et d'un prototype de jeu vidéo en C# avec le framework mono-game.",
            "stack": ['Symfony','VueJS'],
        },

        {
            "title": "Mon portfolio",
            "alt": "projet-portfolio",
            "stack": ['VueJs','ThreeJs'],
            "content": "Portfolio réalisé sous Vue Js. La scène 3D a été modélisée sur MagicaVoxel et Blender puis intégrée avec ThreeJS.",
        },

      {
            "title": "CCI formation",
            "alt": "projets-formation",
            "content": "Divers Projets réalisés en formation (CCI lyon), disponibles sur Github.",
            "stack": ['Symfony','VueJS'],
            "link": "https://github.com/steveKac01"  
        }, 
    ]