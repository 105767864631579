<template>
  <section id="projects">
    <div id="panel">
       <font-awesome-icon icon="fas fa-folder " size="2xl" class="icon"/>
       <h3>Mes réalisations</h3>
      
    </div>

    <!-- PROJECTS -->
    <div id="projects-container">
      <div v-for="(project, id) in projects" :key="id">
        <figure>
          <figcaption>
            <h4>{{ project.title }}</h4>
            <p>{{ project.content }}</p>
            <span class="align-right" v-if="project.link">
              <a :href="project.link" target="_blank">Visiter</a></span
            >
          </figcaption>
          <div v-if="project.link" class="img-container">
            <a :href="project.link" target="_blank">
              <img
                width="558"
                height="515"
                v-lazy="getImgProject(id)"
                :alt="project.alt"
              />
            </a>
            <ul class="label" v-if="project.stack">
                <li  v-for="stack in project.stack" :key="stack">{{ stack }}</li>
              </ul>
          </div>
          <div v-else class="img-container">
            <img
              width="558"
              height="515"
              v-lazy="getImgProject(id)"
              :alt="project.alt"
            />
            
              <ul class="label" v-if="project.stack">
                <li v-for="stack in project.stack" :key="stack">{{ stack }}</li>
              </ul>
             
          </div>
        </figure>
      </div>
    </div>
  </section>
</template>

<script>
import { projects } from "/public/data/FR-fr/project.js";

export default {
  name: "ProjectCompo",
  data: () => ({
    projects,
  }),
  methods: {
    getImgProject(id) {
      try {
        return require(`../assets/img/projects/0` + (id + 1) + `.jpg`);
      } catch {
        return require(`../assets/img/place-holder.jpg`);
      }
    },
  },
};
</script>

<style scoped>
@import "@/assets/style/projects.css";
</style>