
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { 
  faHeart, faFlaskVial, faPeopleGroup, faCaretDown, faMoon, faSun, faChevronUp,faChevronDown, faBars, faAddressBook, faEnvelope, faPerson, faCog, faFolder, faAt, faCheckCircle, faExclamationCircle, faAnglesDown
} from '@fortawesome/free-solid-svg-icons';
import {faWindowClose} from '@fortawesome/free-regular-svg-icons';
import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

library.add(faAnglesDown,faWindowClose,faHeart, faFlaskVial, faPeopleGroup, faCaretDown, faMoon, faSun, faChevronUp,faChevronDown, faBars, faAddressBook, faEnvelope, faPerson, faFolder, faCog, faGithub, faLinkedin, faTwitter, faEnvelope, faAt, faCheckCircle, faExclamationCircle);

export default FontAwesomeIcon