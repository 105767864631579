import Vue from 'vue'
import App from './App.vue'
import store from './store'
import FontAwesomeIcon from './fontAwesome';
import VueLazyload from "vue-lazyload";
import Vuelidate from 'vuelidate'

const loadimage = require('./assets/img/loading.gif')

Vue.use(VueLazyload,{
  loading: loadimage,
});
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
