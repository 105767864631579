import { render, staticRenderFns } from "./IntroScene.vue?vue&type=template&id=a74f7456&scoped=true"
import script from "./IntroScene.vue?vue&type=script&lang=js"
export * from "./IntroScene.vue?vue&type=script&lang=js"
import style1 from "./IntroScene.vue?vue&type=style&index=1&id=a74f7456&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a74f7456",
  null
  
)

export default component.exports